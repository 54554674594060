import React from "react";
import styled, { keyframes } from "styled-components";

const EaseOutWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const EaseOutEntryAnimation = keyframes`
   from {
    opacity: 0%;
   
  }
  to {
    opacity: 100%;
  }
`;

const EaseOutExitAnimation = keyframes`
   from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
`;

const EaseOutEntry = styled.div`
  animation: ${EaseOutEntryAnimation} 350ms ease-in;
  animation-fill-mode: forwards;
`;

const EaseOutExit = styled.div`
  animation: ${EaseOutExitAnimation} 350ms ease-out;
  animation-fill-mode: forwards;
  display: none;
`;

const BookPackAnimation = props => {
  return (
    <>
      <EaseOutWrapper>
        {props.entry ? (
          <EaseOutEntry>{props.children}</EaseOutEntry>
        ) : (
          <EaseOutExit>{props.children}</EaseOutExit>
        )}
      </EaseOutWrapper>
    </>
  );
};

export default BookPackAnimation;
