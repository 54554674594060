import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import Loader from "react-loader-spinner";
import { StaticImage } from "gatsby-plugin-image";

// utils
import { formatNumber } from "../../utils";

// constants
import constant from "../../constants";

// services
import { sendToLSQ } from "../../services/LeadSquared";
import { sendToCT } from "../../services/Clevertap";

// components
import BookPackTermsModal from "./BookPackTermsModal";
import WhatsIncludedModal from "./WhatsIncludedModal";
import AlertPopUp from "../AlertPopUp/AlertPopUp";
import PincodeServicebility from "./PincodeServicebility";
import EaseOutAnimation from "../Animation/BookPackAnimation";
import DropDown from "../DropDown/DropDown";

// api calls
import { doValidatePincode } from "../../actions/bookPackActions";

// css
import * as styles from "./BookPack.module.css";

const BookPackForm = props => {
  const [city, setCity] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [validPinCode, setValidPinCode] = useState(false);
  const [prevPinCode, setPrevPinCode] = useState("");
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showTermsCanvasModal, setShowTermsCanvasModal] = useState(false);
  const [pinCodeCategory, setPinCodeCategory] = useState(""); // set pincode category i.e TIER_I(serviceable), TIER_II(remote), TIER_III(non-serviceable)
  const [pinCodeMessageCategory, setPinCodeMessageCategory] = useState(""); // used to show different messages for different pincode categories
  const [showAlertPopUp, setShowAlertPopUp] = useState(false); // Global Alert Popup Message for non-serviceable pincode
  const [selectedLanguage, setSelectedLanguage] = useState([]); // selected language values ie using multiple checkboxes
  const [showLanguageError, setShowLanguageError] = useState("none"); // show error for language selection if left empty

  let pincodeRef = useRef(null); // To reset pincode input field after successful form submission for TIER_III pincode

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    setValue,
    reset,
  } = useForm({ mode: "all" });

  useEffect(() => {
    setValue("name", props.name || "");
    setValue("email", props.email || "");
    setValue("phone", props.phone || "");
    // if (props.postalCode) {
    //   setValue("pinCode", props.postalCode, { shouldValidate: true });
    // }
  }, [props.name, props.email, props.phone, setValue]);

  const onSubmit = async data => {
    if (loading) {
      return false;
    }

    data.city = city;
    data.pinCode = prevPinCode;
    data.language = selectedLanguage;

    // Reset form for TIER_III pincode when form is submitted
    // after clicking on CTA button for non-serviceable pincode
    if (pinCodeCategory === constant.BOOKPACK_CATEGORIES.TIER_III) {
      reset();
      setPinCodeCategory(""); // reset pincode category
      setValidPinCode(false);
      setPinCodeMessageCategory(""); // reset pincode message category

      pincodeRef.current.value = ""; // To reset pincode input field after successful form submission for TIER_III pincode

      // Show alert popup on form submit
      if (isValid) {
        setShowAlertPopUp(true);
      } else {
        setShowAlertPopUp(false);
      }

      // Saving User Data To LSQ
      let leadSquaredParams = {
        FirstName: data.name,
        Phone: data.phone,
        EmailAddress: data.email,
        mx_Website_CTA: props.leadSquaredParam,
        mx_Latest_Source: "New Website",
      };

      // LSQ Call
      await sendToLSQ(leadSquaredParams);
    }

    // Calling payment function only for serviceable(TIER_I) & Remote(TIER_II) pincodes only
    if (
      pinCodeCategory === constant.BOOKPACK_CATEGORIES.TIER_I ||
      pinCodeCategory === constant.BOOKPACK_CATEGORIES.TIER_II
    ) {
      if (selectedLanguage.length > 0) props.handlePayNow(data);
    }
  };

  const validatePinCode = async value => {
    if (prevPinCode === value) {
      return true;
    }

    let pattern = /^[1-9][0-9]{5}$/;

    if (!pattern.test(value)) {
      setPinCodeCategory(""); // reset pincode category
      setValidPinCode(false);
      setLoading(false);
      reset(); // reset form
      props.handlePincodePricing(pinCodeCategory);
      setError("Please enter your valid pin code");
      return "Please enter your valid pin code";
    } else {
      try {
        setLoading(true);
        setValidPinCode(false);
        setError("");
        const response = await doValidatePincode(value);
        const foundPincode = response.results;
        setLoading(false);

        const labValue = foundPincode[0]["labServiceability"] || "";
        const tsaValue = foundPincode[0]["tsaServiceability"] || "";
        const city = foundPincode[0].city || "";
        const category = foundPincode[0]["category"] || "";

        setCity(city);
        setPinCodeCategory(category);
        setValidPinCode(false);
        setPinCodeMessageCategory("");

        // set pincode category based on lab and tsa values coming from pincode api
        if (labValue === "Yes" && tsaValue === "Yes") {
          setPinCodeMessageCategory(constant.BOOKPACK_CATEGORIES.TIER_I);
        } else if (labValue === "Yes" && tsaValue === "No") {
          setValidPinCode(true);
          setPinCodeMessageCategory(constant.BOOKPACK_CATEGORIES.TIER_II);
        } else {
          setPinCodeCategory(category);
          setPinCodeMessageCategory("");
        }
        setPrevPinCode(value);
        setValidPinCode(true);
        props.handlePincodePricing(category);
        return true;
      } catch (error) {
        setLoading(false);
        console.error("Pin code server error", error);
        setError("Please enter your valid pin code");
        return "Pin code server error";
      }
    }
  };

  // Terms & conditions Modal
  const handleTermsModal = () => {
    setShowTermsCanvasModal(true);
    setShowTermsModal(true);
  };

  // Alert Message Modal - Non-serviceable pincode
  const handleAlertPopUpModal = () => {
    // popup modal will show only when all form fields are filled
    if (isValid) {
      setShowAlertPopUp(true);
    } else {
      setShowAlertPopUp(false);
    }
  };

  // Handle language selection
  const handleLanguageSelection = value => {
    if (value.length > 0) {
      setShowLanguageError("none");
      setSelectedLanguage(value);
    } else {
      setShowLanguageError("block");
    }
  };

  return (
    <>
      <div>
        <div className={`container twinContainer ${styles.bookPackForm_sec}`}>
          {/* Text */}
          <div className={`${styles.bookPack_text_sec}`}>
            <div className="row">
              <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                <div className={styles.bookPackForm_cnt_1_sec}>
                  <p>Join Twin’s 1-year program</p>
                </div>
              </div>
              <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3">
                {props.windowSize && props.windowSize.width <= 768 ? (
                  <div
                    className={styles.bookPackForm_cnt_2_sec}
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasBottom"
                    aria-controls="offcanvasBottom"
                    onClick={() =>
                      sendToCT("Enroll Program Viewed", {
                        action: "Enroll_Program_Inclusion_Referral Clicked",
                      })
                    }
                    role="presentation"
                  >
                    <p>What’s included in the plan?</p>
                  </div>
                ) : (
                  <div
                    className={styles.bookPackForm_cnt_2_sec}
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight"
                    onClick={() =>
                      sendToCT("Enroll Program Viewed", {
                        action: "Enroll_Program_Inclusion_Referral Clicked",
                      })
                    }
                    role="presentation"
                  >
                    <p>What’s included in the plan?</p>
                  </div>
                )}
              </div>
            </div>
            <div style={{ position: "relative" }}>
              <div className={styles.bookpackForm_mob_line}></div>
              {/* <div className="row mt-2">
                <div className="col-12">
                  <div className={styles.bookPackForm_plan_txt}>
                    <p>Choose your plan</p>
                  </div>
                </div>
              </div> */}
              <div className={styles.bookpackForm_desk_line}></div>
              {/* Popular Card */}
              <div className={styles.bookpackForm_poplr_crd}>
                <div className={styles.bookpackForm_poplrCrd_bkg1}></div>
                <StaticImage
                  src="../../images/bookPack/popular.png"
                  alt=""
                  className="img-fluid"
                />
                <p>POPULAR</p>
                <div className={styles.bookpackForm_poplrCrd_bkg2}></div>
              </div>
            </div>
          </div>
          {/* Card  */}
          <PincodeServicebility
            loading={loading}
            error={error}
            pinCodeMessageCategory={pinCodeMessageCategory}
            validPinCode={validPinCode}
            annualPlanPrice={props.annualPlanPrice}
            validatePinCode={validatePinCode}
            pincodeRef={pincodeRef}
          />
          {/* End - Card */}

          {/* Start -> Remote Pincode Reader Content Section */}
          {pinCodeCategory === constant.BOOKPACK_CATEGORIES.TIER_II ? (
            <div>
              <div className="row">
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                  <div className={`row ${styles.bookpackForm__reader}`}>
                    <div
                      className={` col-xs-12 col-sm-12 col-md-4 col-lg-4 ${styles.bookpackForm__readerImg}`}
                    >
                      <StaticImage
                        src="../../images/bookPack/Mask_Group.png"
                        alt="mask_group"
                        className="img-fluid"
                        placeholder="blurred"
                        quality={90}
                        width={97}
                        height={88}
                      />
                    </div>
                    <div
                      className={`col-8 ${styles.bookpackForm__readerCntnt}`}
                    >
                      <h3>Freestyle libre reader</h3>
                      <p>
                        High quality, FDA-approved sensor reader made in the USA
                        & Germany.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={`col-xs-12 col-sm-6 col-md-6 col-lg-6 ${styles.bookpackForm__readerCntnt_scnd}`}
                >
                  <p>
                    Reader provides convenience to take your daily readings and
                    share it with the care team instantly.
                  </p>
                </div>
              </div>
              <div className={styles.bookpackForm__readerSec_line}></div>
            </div>
          ) : null}
          {/* End -> Remote Pincode Reader Content Section */}

          {/* Start - Form Text */}
          {pinCodeCategory === constant.BOOKPACK_CATEGORIES.TIER_I ||
          pinCodeCategory === constant.BOOKPACK_CATEGORIES.TIER_II ? (
            // This form section is for serviceable and remote pin code both.
            <div>
              <EaseOutAnimation
                entry={
                  pinCodeCategory === constant.BOOKPACK_CATEGORIES.TIER_I ||
                  pinCodeCategory === constant.BOOKPACK_CATEGORIES.TIER_II
                    ? true
                    : false
                }
              >
                <div className="mt-4">
                  <div className="row">
                    <div className="col-12">
                      <div className={styles.bookpackForm_formTxt}>
                        <p>Complete user details</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End - Form Text */}
                {/* Form */}
                <div>
                  <form name="bookPack" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      {/* Start - Form_Input_Boxes */}
                      {/* Name */}
                      <div className={`col-md-4 ${styles.bookpackForm_form}`}>
                        <div
                          className={`form-group ${styles.bookpackForm_formGrp}`}
                        >
                          <label className="mb-2" htmlFor="name">
                            Full Name*
                          </label>
                          <input
                            className={
                              errors.name
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            placeholder="Full name"
                            id="name"
                            type="text"
                            {...register("name", {
                              required: "Please enter your name",
                              pattern: {
                                value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                                message: "Please enter your valid name",
                              },
                              maxLength: {
                                value: 50,
                                message: "Name is too long",
                              },
                            })}
                          />
                          {errors.name && (
                            <span className="invalid-feedback">
                              {errors.name.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {/* Phone */}
                      <div className={`col-md-4 ${styles.bookpackForm_form}`}>
                        <div
                          className={`form-group ${styles.bookpackForm_formGrp}`}
                        >
                          <label className="mb-2" htmlFor="phone">
                            Phone*
                          </label>
                          <input
                            className={`form-control letterSp1 ${
                              errors.phone ? "is-invalid" : ""
                            }`}
                            placeholder="Phone number"
                            id="phone"
                            type="text"
                            inputMode="numeric"
                            maxLength="10"
                            {...register("phone", {
                              required: "Please enter your mobile number",
                              pattern: {
                                value:
                                  /^\+?([1-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/, // eslint-disable-line no-useless-escape
                                message: "Please enter your valid phone number",
                              },
                              maxLength: {
                                value: 10,
                                message: "Invalid phone number",
                              },
                            })}
                          />
                          {errors.phone && (
                            <span className="invalid-feedback">
                              {errors.phone.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {/* Email */}
                      <div className={`col-md-4 ${styles.bookpackForm_form}`}>
                        <div
                          className={`form-group ${styles.bookpackForm_formGrp}`}
                        >
                          <label className="mb-2" htmlFor="email">
                            Email*
                          </label>
                          <input
                            className={
                              errors.email
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            placeholder="Email id"
                            id="email"
                            type="email"
                            {...register("email", {
                              required: "Please enter your email",
                              pattern: {
                                value:
                                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line no-useless-escape
                                message: "Please enter your valid email",
                              },
                            })}
                          />
                          {errors.email && (
                            <span className="invalid-feedback">
                              {errors.email.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {/* Languange */}
                      <div className="col-12 col-md-4">
                        <div
                          className={`form-group mb-3 ${styles.bookpackForm__lngFormInput}`}
                        >
                          <label htmlFor="language">Preferred Language* </label>
                          <DropDown
                            displayValue="display"
                            onSelect={handleLanguageSelection}
                            onRemove={handleLanguageSelection}
                            options={constant.BOOKPACK_LANGUAGES}
                            showCheckbox={true}
                            selectionLimit={2}
                            placeholder="Select a language (upto two)"
                            hidePlaceholder={true}
                            showArrow={true}
                            customCloseIcon={
                              <StaticImage
                                src="../../images/bookPack/cross_2.png"
                                alt="cross_icon"
                                className="img-fluid"
                                placeholder="blurred"
                                quality={90}
                                style={{
                                  width: "14px",
                                  height: "14px",
                                }}
                              />
                            }
                            customArrow={
                              <StaticImage
                                src="../../images/bookPack/arrow_dropdown.png"
                                alt="cross_icon"
                                className="img-fluid"
                                placeholder="blurred"
                                quality={90}
                                style={{
                                  marginTop: "9px",
                                }}
                              />
                            }
                          />
                          {/* To show language error */}
                          {selectedLanguage.length === 0 && (
                            <span
                              className="text-danger"
                              style={{
                                fontSize: "0.875em",
                                display: showLanguageError,
                              }}
                            >
                              <p style={{ marginTop: "4px" }}>
                                Please select a language
                              </p>
                            </span>
                          )}
                        </div>
                      </div>
                      {/* Applied Coupon */}
                      <div
                        className={`col-md-4 text-success ${styles.bookpackForm_referralCpn_sec}`}
                      >
                        <p>
                          Congratulations! Your referral discount of Rs{" "}
                          {props.referedDiscountAmt} is auto applied.
                        </p>
                      </div>
                      {/* End - Form_Input_Boxes */}

                      {/* Start - Terms_&_Conditions  */}
                      <div className="col-12 mt-4">
                        <div className={styles.bookpackForm_terms_condns_sec}>
                          <StaticImage
                            src="../../images/bookPack/check-mark.svg"
                            alt=""
                            className="img-fluid"
                            style={{
                              opacity: "0.54",
                              marginTop: "3px",
                              height: "15px",
                              width: "15px",
                            }}
                          />
                          <label htmlFor="terms_conditions">
                            I agree to all{" "}
                            <span
                              onClick={() => handleTermsModal()}
                              role="presentation"
                            >
                              Terms & Conditions
                            </span>
                          </label>
                        </div>
                      </div>
                      {/* End - Terms_&_Conditions  */}

                      {/* Start - Buy_Now CTA Button */}
                      <div className={`col-12 mt-4`}>
                        <div className="buttonClass">
                          <span className="errorclass text-center text-danger">
                            {!isValid &&
                              isSubmitted &&
                              "Please fill all the fields correctly"}
                          </span>
                          <button
                            type="submit"
                            className={`${styles.bookpackForm_cta} ${
                              props.loading && styles.bookpackForm_cta_event
                            }`}
                            onClick={() => setShowLanguageError("block")}
                          >
                            <div className="bookBtn" id="book-now">
                              <span
                                className={`d-flex align-items-center ${
                                  props.loading &&
                                  styles.bookpackForm_cta_txt_evnt
                                }`}
                              >
                                <span className="bookBtn">Buy Now at</span>
                                <span
                                  className={`bookBtn bookBtnAmt ${styles.bookpackForm_cta_amt}`}
                                >
                                  <p>{formatNumber(props.annualPlanPrice)}</p>
                                </span>
                                <span
                                  className={`icon-arrow-forward ${styles.bookpackForm_cta_arrw}`}
                                  aria-hidden="true"
                                ></span>
                              </span>

                              <span className={styles.bookpackForm_cta_loader}>
                                <Loader
                                  type="ThreeDots"
                                  color="#FFF"
                                  height={30}
                                  width={30}
                                  visible={props.loading}
                                />
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                      {/* End - Buy_Now CTA Button */}
                    </div>
                  </form>
                </div>
                {/* End- Form */}
              </EaseOutAnimation>
            </div>
          ) : (
            // This form section is for non serviceable pin code only.
            <div>
              <EaseOutAnimation
                entry={
                  pinCodeCategory === constant.BOOKPACK_CATEGORIES.TIER_III
                    ? true
                    : false
                }
              >
                <div className="mt-4">
                  <div className="row">
                    <div className="col-12">
                      <div
                        className={styles.bookpackForm__formHdng}
                        data-testid="bookpack_formHdng"
                      >
                        <p>Register for an alert</p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Form */}
                <div>
                  <form
                    name="bookPack"
                    onSubmit={handleSubmit(onSubmit)}
                    data-testid="bookPackForm_form"
                  >
                    <div className="row">
                      {/* Start - Form_Input_Boxes */}
                      {/* Name */}
                      <div className={`col-md-4 ${styles.bookpackForm_form}`}>
                        <div
                          className={`form-group ${styles.bookpackForm_formGrp}`}
                        >
                          <label className="mb-2" htmlFor="name">
                            Full Name*
                          </label>
                          <input
                            className={
                              errors.name
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            placeholder="Full name"
                            id="name"
                            type="text"
                            data-testid="bookpack_name"
                            {...register("name", {
                              required: "Please enter your name",
                              pattern: {
                                value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                                message: "Please enter your valid name",
                              },
                              maxLength: {
                                value: 50,
                                message: "Name is too long",
                              },
                            })}
                          />
                          {errors.name && (
                            <span className="invalid-feedback">
                              {errors.name.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {/* Phone */}
                      <div className={`col-md-4 ${styles.bookpackForm_form}`}>
                        <div
                          className={`form-group ${styles.bookpackForm_formGrp}`}
                        >
                          <label className="mb-2" htmlFor="phone">
                            Phone*
                          </label>
                          <input
                            className={`form-control letterSp1 ${
                              errors.phone ? "is-invalid" : ""
                            }`}
                            placeholder="Phone number"
                            id="phone"
                            type="text"
                            inputMode="numeric"
                            maxLength="10"
                            data-testid="bookpack_phone"
                            {...register("phone", {
                              required: "Please enter your mobile number",
                              pattern: {
                                value:
                                  /^\+?([1-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/, // eslint-disable-line no-useless-escape
                                message: "Please enter your valid phone number",
                              },
                              maxLength: {
                                value: 10,
                                message: "Invalid phone number",
                              },
                            })}
                          />
                          {errors.phone && (
                            <span className="invalid-feedback">
                              {errors.phone.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {/* Email */}
                      <div className={`col-md-4 ${styles.bookpackForm_form}`}>
                        <div
                          className={`form-group ${styles.bookpackForm_formGrp}`}
                        >
                          <label className="mb-2" htmlFor="email">
                            Email*
                          </label>
                          <input
                            className={
                              errors.email
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                            placeholder="Email id"
                            id="email"
                            type="email"
                            data-testid="bookpack_email"
                            {...register("email", {
                              required: "Please enter your email",
                              pattern: {
                                value:
                                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line no-useless-escape
                                message: "Please enter your valid email",
                              },
                            })}
                          />
                          {errors.email && (
                            <span className="invalid-feedback">
                              {errors.email.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {/* End - Form_Input_Boxes */}

                      {/* Start - Buy_Now CTA Button */}
                      <div className={`col-12 mt-4`}>
                        <div className="buttonClass">
                          {/* <span className="errorclass text-center text-danger">
                          {!isValid &&
                            isSubmitted &&
                            "Please fill all the fields correctly"}
                        </span> */}
                          <button
                            type="submit"
                            className={`${styles.bookpackForm_cta}`}
                            onClick={handleAlertPopUpModal}
                          >
                            <div>
                              <span className={`d-flex align-items-center`}>
                                <span className="bookBtn">
                                  Register for Invite
                                </span>
                                <span
                                  className={`icon-arrow-forward ${styles.bookpackForm_cta_arrw}`}
                                  aria-hidden="true"
                                ></span>
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                      {/* End - Buy_Now CTA Button */}
                    </div>
                  </form>
                </div>
                {/* End- Form */}
              </EaseOutAnimation>
            </div>
          )}
        </div>

        {/* Terms & Conditions Modal */}
        <BookPackTermsModal
          show={showTermsModal}
          onHide={() => setShowTermsModal(false)}
          showTermsCanvasModal={showTermsCanvasModal}
          onHideCanvasModal={() => setShowTermsCanvasModal(false)}
          windowSize={props.windowSize}
        />

        {/* Whats Included Modal */}
        {props.windowSize && props.windowSize.width <= 768 ? (
          <WhatsIncludedModal
            id="offcanvasBottom"
            windowSize={props.windowSize}
          />
        ) : (
          <WhatsIncludedModal
            id="offcanvasRight"
            windowSize={props.windowSize}
          />
        )}

        {/* Alert Message PopUp -> Non-Serviceable pincode */}
        <AlertPopUp
          text="Thank you for registering for launch invite! we will contact you soon."
          showAlertPopUp={showAlertPopUp}
          onHideAlertPopUpModal={() => setShowAlertPopUp(false)}
        />
      </div>
    </>
  );
};

export default BookPackForm;
