import React, { useEffect } from "react";

// constants
import constant from "../../constants";

// css
import * as styles from "./BookPack.module.css";

const PincodeServicebility = props => {
  const { postalCode, validatePinCode } = props;

  useEffect(() => {
    postalCode && validatePinCode(postalCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postalCode]);

  return (
    <div className="pinServicebility">
      <div className={styles.bookpackForm__crdSec}>
        <div className="row">
          {/* Pincode section */}
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            <div className={styles.bookpackForm__pinHdng}>
              <p>Check serviceability</p>
            </div>
            <div>
                <div className={`col-md-9 ${styles.bookpackForm__pinSec}`}>
                  <div className={`form-group ${styles.bookpackForm_formGrp}`}>
                    <div>
                      <label className="mb-2" htmlFor="postalCode">
                        Pin Code*
                      </label>
                      <input
                        className={`form-control letterSp1 
                        ${props.error ? "is-invalid" : ""}`}
                        id="postalCode"
                        type="text"
                        maxLength="6"
                        defaultValue={props.postalCode}
                        inputMode="numeric"
                        ref={props.pincodeRef}
                        onChange={e => props.validatePinCode(e.target.value)}
                      />
                      {props.loading ? (
                        <span style={{ fontSize: "80%" }}>Loading...</span>
                      ) : (
                        props.error && (
                          <span
                            className="text-danger"
                            style={{ fontSize: "14px" }}
                          >
                            {props.error}
                          </span>
                        )
                      )}
                    </div>
                  </div>
                </div>
            </div>
          </div>
          {/* Annual Card */}
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
            {props.validPinCode ? (
              <div>
                {props.pinCodeMessageCategory ===
                constant.BOOKPACK_CATEGORIES.TIER_I ? (
                  <div
                    className={`text-success ${styles.bookPackForm__mssgsDiv}`}
                    style={{ fontSize: "15px" }}
                  >
                    {constant.BOOKPACK_PINCODE_MESSAGES.serviceable.msg1}{" "}
                    <span style={{ color: "#111111" }}>
                      {constant.BOOKPACK_PINCODE_MESSAGES.serviceable.msg2}
                    </span>
                  </div>
                ) : props.pinCodeMessageCategory ===
                  constant.BOOKPACK_CATEGORIES.TIER_II ? (
                  <div
                    className={`text-success ${styles.bookPackForm__mssgsDiv}`}
                    style={{ fontSize: "15px" }}
                  >
                    {constant.BOOKPACK_PINCODE_MESSAGES.remote.msg1}{" "}
                    <span style={{ color: "#111111" }}>
                      {constant.BOOKPACK_PINCODE_MESSAGES.remote.msg2}
                    </span>
                  </div>
                ) : (
                  <div
                    className={`text-danger ${styles.bookPackForm__mssgsDiv}`}
                    style={{ fontSize: "15px" }}
                  >
                    {constant.BOOKPACK_PINCODE_MESSAGES.notServiceable.msg1}{" "}
                    <span style={{ color: "#111111" }}>
                      {constant.BOOKPACK_PINCODE_MESSAGES.notServiceable.msg2}
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.bookPackForm__pinPara}>
                <p>
                  Please share your nearest pincode to whether twin is
                  serviceable in your location
                </p>
              </div>
            )}
          </div>
          {/* End - Annual Card */}
        </div>
        {/* End - Row */}
      </div>
    </div>
  );
};

export default PincodeServicebility;
