import React, { Component } from "react";
import queryString from "query-string";

// app header
import AppHeader from "../components/AppHeader/AppHeader";

// seo
import Seo from "../components/Seo";

// utils
import { validateServiceabilityBookPack } from "../utils";

// constant
import constant from "../constants";

// hook
import { useBookPackQuery } from "../hooks/useBookPackQuery";
import { useWindowSize } from "../hooks/useWindowSize";

// services
import { sendToCT } from "../services/Clevertap";
import { sendToLSQ } from "../services/LeadSquared";

// email
import { sendEmailNotifecation } from "../services/SendEmail";

// Local Storage
import LS from "../utils/LocalStorageService";

// componentts
import BookPackHeading from "../components/BookPack/BookPackHeading";
import BookPackReferralForm from "../components/BookPack/BookPackReferralForm";

// api actions
import {
  getClientDetails,
  getReferralsDetails,
  updateRefreeStatus,
  updateClientAddress,
  handlePaymentOfBookPack,
} from "../actions/bookPackActions";

class BookPackReferral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      serverError: "",
      type: "DIRECT_M2_ANNUAL",
      planCode: "",
      planPrice: 0,
      clintInfo: {},
      refererInfo: {},
      referralCode: "",
      clientId: "",
      referedDiscountAmt: 0,
      annualPlanPrice: 0,
      selectedPlan: "annualPlan",
      productId: "",
      pincodeCategory: "",
    };
  }

  // Get Info about the client/user based on clientId --> IC Api Call
  getInitialData = async clientId => {
    try {
      // api call -> to get client details
      const response = await getClientDetails(clientId);
      this.setState({ clintInfo: response.results[0], fetching: true });
    } catch (error) {
      console.error("client api server error", error);
      this.setState({
        serverError: "Unable to fetch your details. Try again",
        fetching: true,
      });
    }
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    //getting referre Id and referral code from local storage
    const lsValue1 = LS.getValue("referralCode");
    const { clientId } = queryString.parse(this.props.location.search);

    try {
      // api call -> referrals info based on referral code
      const response = await getReferralsDetails(lsValue1.referralCode);
      this.setState({
        refererInfo: response.results,
        referedDiscountAmt: response.results.rewards.operationValue,
      });
    } catch (error) {
      console.error("Referral api server error", error);
      this.setState({
        serverError: constant.BOOKPACK_ERROR_MESSAGES.serverError,
      });
    }

    const annualPlan = this.props.bookPack.annualPlan[0];

    const finalAnnualPlanPrice =
      Number(annualPlan.planPrice) - Number(this.state.referedDiscountAmt);

    annualPlan &&
      this.setState({
        planCode: annualPlan.planCode,
        planPrice: finalAnnualPlanPrice,
        planDay: annualPlan.planDay,
        type: annualPlan.planType,
        annualPlanPrice: finalAnnualPlanPrice,
        referralCode: lsValue1.referralCode,
        clientId: clientId,
      });

    if (clientId) {
      this.getInitialData(clientId);
    }
  }

  // Function -> To handle the payment of book pack
  handlePayNow = async values => {
    // api call for refree status
    try {
      this.setState({ loading: true });
      await updateRefreeStatus(
        this.state.referralCode,
        this.state.clientId,
        "ANNUAL"
      );
      this.setState({ loading: false });
    } catch (error) {
      console.error("Referral api server error", error);
      this.setState({
        loading: false,
        serverError: "No referal details found for current user",
      });
      return false;
    }

    //api to update address of the new user
    const updateClientInfoBody = {
      id: this.state.clientId,
      address: {
        city: values.city,
        line1: "",
        postalCode: values.pinCode ? values.pinCode : "",
      },
    };

    try {
      this.setState({ loading: true });
      await updateClientAddress(updateClientInfoBody);
      this.setState({ loading: false });
    } catch (error) {
      console.error("Client info update server error", error);
      this.setState({
        loading: false,
        serverError: "Client details not get updated",
      });
      return false;
    }

    // payment api
    const languageArr = [];
    if (values.language && values.language !== "") {
      values.language.map(item => {
        languageArr.push(item.name);
        return languageArr;
      });
    }

    // leadSquared params
    let leadSquaredParams = {
      FirstName: values.name,
      Phone: values.phone,
      EmailAddress: values.email,
      mx_Website_CTA: "book_pack_referral",
      mx_Latest_Source: "New Website",
    };

    let paymentBody = {
      firstName: values.name.split(" ")[0] || values.name,
      lastName: values.name.split(" ")[1] || "",
      mobileNumber: values.phone,
      email: values.email,
      planCode: this.state.planCode,
      preferredM2Plan: this.state.type,
      m1PlanCode: this.state.planCode,
      m2PlanCode: this.state.planCode,
      sourceType: "DIGITAL_ORGANIC_TW",
      languageList: languageArr,
      address: {
        postalCode: values.pinCode ? values.pinCode : "",
        city: values.city,
        line1: "",
      },
    };

    try {
      this.setState({ loading: true });
      // LSQ Call
      await sendToLSQ(leadSquaredParams);
      // Zoho Payment Call
      const response = await handlePaymentOfBookPack(paymentBody);
      const paymentUrl = response.results[0].url;

      this.setState({ loading: false });
      window.location.href = paymentUrl;

      // clevertap event
      sendToCT("Enrollment Initiated Referral", {
        action: "BookPack_Referral_Buy_Now Clicked",
        name: values.name,
        phone: values.phone,
        email: values.email,
        pincode: values.pinCode,
        referralCode: this.state.referralCode,
        paymentPlan: this.state.planCode,
      });
    } catch (error) {
      console.error("payment api server error", error);
      this.setState({
        loading: false,
        serverError:
          "There seems to be an issue at our end. Please call +91 9005008946 to complete the payment",
      });
      // send an email
      sendEmailNotifecation(
        window.location.href,
        paymentBody,
        error.response.data || {},
        "/marketing/v3/prospect"
      );
    }
  };

  // This function updates the planCode and planPrice
  // based on the pincode category i.e GOLD or SILVER only
  // and for PLATINUM category planPrice and planCode will not get updated.
  handlePincodePricing = async category => {
    try {
      const planData = await validateServiceabilityBookPack(
        category,
        this.props.bookPack.annualPlan[0]
      );

      if (planData.categoryVal) {
        this.setState({
          planCode: planData.planCode,
          planPrice: Number(planData.planPrice) - Number(this.state.referedDiscountAmt),
          annualPlanPrice: Number(planData.planPrice) - Number(this.state.referedDiscountAmt),
          pincodeCategory: category,
        });
      } else {
        // for PLATINUM category(Non-serviceable) pincode pricing will get updated to default value.
        this.setState({
          planCode: planData.planCode,
          planPrice: Number(planData.planPrice) - Number(this.state.referedDiscountAmt),
          annualPlanPrice: Number(planData.planPrice) - Number(this.state.referedDiscountAmt),
          pincodeCategory: category,
        });
      }
    } catch (error) {
      console.error("Error in getting plan details based on pincode", error);
    }
  };

  render() {
    const {
      annualPlanPrice,
      clintInfo,
      loading,
      productId,
      planPrice,
      planDay,
      referedDiscountAmt,
      selectedPlan,
      serverError,
    } = this.state;

    const { appHeader } = queryString.parse(this.props.location.search);

    return (
      <div className="bgLightgray" style={{ minHeight: "92vh" }}>
        <Seo
          title="Diabetes Reversal Program"
          description="Start your diabetes reversal journey. 1000's have reversed their diabetes using Twin's proven AI based precision treatment."
        />

        {appHeader === "mobile" && <AppHeader text="BOOK PACK" isBack={true} />}

        {/* Heading */}
        <BookPackHeading />

        {/* Form */}
        <BookPackReferralForm
          price={planPrice}
          handlePayNow={this.handlePayNow}
          loading={loading}
          selectedPlan={selectedPlan}
          name={clintInfo.name}
          email={clintInfo.email}
          phone={clintInfo.phone}
          postalCode={clintInfo.postalCode}
          locationSearch={this.props.location.search}
          productId={productId}
          annualPlanPrice={annualPlanPrice}
          planDay={planDay}
          handlePincodePricing={this.handlePincodePricing}
          showCouponSection={false}
          leadSquaredParam="book_pack_referral"
          referedDiscountAmt={referedDiscountAmt}
          windowSize={this.props.windowSize}
        />
        <div className="mt-2 text-center text-danger">{serverError} &nbsp;</div>
      </div>
    );
  }
}

const WithContext = props => {
  const bookPackPayload = useBookPackQuery("referral");
  const windowSize = useWindowSize();
  return (
    <BookPackReferral
      {...props}
      bookPack={bookPackPayload}
      windowSize={windowSize}
    />
  );
};

export default WithContext;
